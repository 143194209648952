.form-check {
  margin-left: 35px;
}

.songInfo {
  padding-top: 20px;
}

.footer {
  padding-top: 20px;
}

@media screen and (max-width: 640px) {
  .songInfo {
    padding-top: 10px;
    margin-left: 0px;
  }
}
